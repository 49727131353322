.curve {
  border-radius: 0.35rem;
}
.shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.rcardnew {
  width: 90%;
  height: 100px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
  overflow: hidden;
  background-color: #fff;
}
@media (min-width: 768px) {
  .rcardnew div:first-child {
    flex-basis: 30%;
  }
}
.rcardnew img {
  display: block;
  width: 100px !important;
  margin-top: 15px !important;
}
