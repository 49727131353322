.custom-table-container {
  max-width: 1200px;
  margin: auto;
}

.custom-table {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-table th,
.custom-table td {
  border-color: #dee2e6;
}

.custom-icon {
  font-size: 20px;
  margin-right: 5px;
}

.view-icon {
  color: #007bff; /* Blue color for the view icon */
}

.download-icon {
  color: #28a745; /* Green color for the download icon */
}

.icon-label {
  vertical-align: middle;
  font-size: 14px;
  color: #333;
}

.custom-table thead th {
  background-color: #f8f9fa; /* Light gray background for table header */
  border-bottom: 2px solid #dee2e6; /* Border bottom for table header */
}

.custom-table tbody tr:hover {
  background-color: #f8f9fa; /* Light gray background on hover */
}
