.scrollbar {
  padding: 0;
  margin: 0;
  color: #800000;
  background-color: #800000;
  height: 53px;
  border: none !important;
}

.ripple {
  position: relative;
  overflow: hidden;
}

.label {
  float: left;
  padding: 6px 15px;
  background: #d6002a;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.ripple::after {
  animation: ripple 1.5s ease-out infinite;
}

.ripple:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.marqueenews {
  font-size: 16px;
  line-height: 1;
  color: white;
  font-weight: 700;
  width: 100%;
  margin-top: 10px;
}

.marquee {
  height: 53px;
  overflow: hidden;
  position: relative;
}

.marquee p {
  white-space: nowrap;
  animation: marquee-scroll 50s cubic-bezier(1, 0.99, 0.03, 0.07) infinite;
  color: white;
  margin: 0;
  padding: 0;
  top: 0;
}

.marquee p span {
  display: inline-block;
}

.marquee p.show {
  opacity: 1;
}

.news1-gif {
  height: 42px !important;
  margin-top: -4px !important;
}

@keyframes marquee-scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
